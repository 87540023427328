import { Action } from '@ngrx/store';
import { Organisatie } from '@core-models-organisatie';
import { Update } from '@ngrx/entity/src/models';

export enum OrganisatieActionTypes {
  LOAD_ALL = '[Organisatie] Load All',
  LOAD_ALL_SUCCESS = '[Organisatie] Load All Success',

  LOAD = '[Organisatie] Load',
  LOAD_SUCCESS = '[Organisatie] Load Success',

  CREATE = '[Organisatie] Create',
  CREATE_SUCCESS = '[Organisatie] Create Success',

  PATCH = '[Organisatie] Patch',
  PATCH_SUCCESS = '[Organisatie] Patch Success',

  DELETE = '[Organisatie] Delete',
  DELETE_SUCCESS = '[Organisatie] Delete Success',

  FAILURE = '[Organisatie] Failure',

  SET_CURRENT_ORGANISATIE_ID = '[Organisatie] Set current organisatieId'
}

export class SetCurrentOrganisatieId implements Action {
  readonly type = OrganisatieActionTypes.SET_CURRENT_ORGANISATIE_ID;
  constructor(public payload: string) {}
}

// export class ClearSelect implements Action {
//   readonly type = OrganisatieActionTypes.CancelSelect;
// }

export class Load implements Action {
  readonly type = OrganisatieActionTypes.LOAD;
  constructor(public payload: string) {}
}

export class LoadSuccess implements Action {
  readonly type = OrganisatieActionTypes.LOAD_SUCCESS;
  constructor(public payload: Organisatie) {}
}

export class LoadAll implements Action {
  readonly type = OrganisatieActionTypes.LOAD_ALL;
  constructor(public payload = null, public organisatieId: string) {}
}

export class LoadAllSuccess implements Action {
  readonly type = OrganisatieActionTypes.LOAD_ALL_SUCCESS;
  constructor(public payload: Organisatie[]) {}
}

export class Create implements Action {
  readonly type = OrganisatieActionTypes.CREATE;

  constructor(public payload: Organisatie) {}
}

export class CreateSuccess implements Action {
  readonly type = OrganisatieActionTypes.CREATE_SUCCESS;

  constructor(public payload: Organisatie) {}
}

export class Patch implements Action {
  readonly type = OrganisatieActionTypes.PATCH;

  constructor(public payload: Organisatie) {}
}

export class PatchSuccess implements Action {
  readonly type = OrganisatieActionTypes.PATCH_SUCCESS;

  constructor(public payload: Update<Organisatie>) {}
}

export class Delete implements Action {
  readonly type = OrganisatieActionTypes.DELETE;

  constructor(public payload: string) {}
}

export class DeleteSuccess implements Action {
  readonly type = OrganisatieActionTypes.DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export class Failure implements Action {
  readonly type = OrganisatieActionTypes.FAILURE;
  constructor(public payload: { concern: 'CREATE' | 'UPDATE' | 'LOAD' | 'DELETE'; error: any }) {}
}

export type All =
  | SetCurrentOrganisatieId
  | LoadAll
  | Load
  | Create
  | Patch
  | Delete
  | LoadAllSuccess
  | LoadSuccess
  | PatchSuccess
  | CreateSuccess
  | DeleteSuccess
  | Failure;
