// tslint:disable-next-line:max-line-length
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    // tslint:disable-next-line:max-line-length
    MatButtonModule, MatCheckboxModule, MatAutocompleteModule, MatDatepickerModule, MatFormFieldModule, MatRadioModule, MatInputModule, MatSelectModule, MatSliderModule, MatSlideToggleModule
  ],
  exports: [
    // tslint:disable-next-line:max-line-length
    MatButtonModule, MatCheckboxModule, MatAutocompleteModule, MatDatepickerModule, MatFormFieldModule, MatRadioModule, MatInputModule, MatSelectModule, MatSliderModule, MatSlideToggleModule
  ]
})
export class MaterialFormsModule { }
