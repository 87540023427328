import { BaseComponent } from "src/app/core/base/base.component";
import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromAuth from "@core-store-auth/index";
import * as authActions from "@core-store-auth/auth.actions";
import { Router } from "@angular/router";
import { take, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { Gebruiker } from "@core-models/gebruiker";
import { environment } from "../../environments/environment";

@Component({
  selector: "eci-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent extends BaseComponent implements OnInit {
  gebruiker: Gebruiker;
  environment = environment;
  isBeheerder = false;
  isTaasc = false;
  isAdmin = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  constructor(
    private store: Store<fromAuth.State>,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
  }

  ngOnInit() {
    // this.subscriptions.push(
    //   this.store.pipe(
    //     select(fromAuth.selectIsAuthenticated)
    //   ).subscribe(isAuthenticated => {
    //     console.log('[layout.component]');
    //     if (!isAuthenticated) {
    //       this.router.navigate(['login']);
    //     }
    //   })
    // );
    this.subscriptions.push(
      this.store
        .pipe(select(fromAuth.selectAuthUser))
        .subscribe((gebruiker) => {
          this.gebruiker = gebruiker;
          this.isBeheerder = gebruiker.rollen.beheerder || false;
          this.isAdmin = gebruiker.rollen.admin || false;
          this.isTaasc = gebruiker.organisatieId == "taasc";
        })
    );

    this.store.dispatch(new authActions.GetUser());
  }

  logout() {
    console.log("logout");
    this.router.navigate(["login"], { queryParams: { logoff: true } });
  }
}
