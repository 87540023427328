import { Project } from '@core-models-project';
import {
  All as AllProjectenActions,
  ProjectActionTypes
} from '@core-store-projecten/project.actions';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';

export const projectenAdapter = createEntityAdapter<Project>({
  selectId: (project: Project) => project.documentId,
  sortComparer: false
});

export interface State extends EntityState<Project> {
  currentProjectId?: string;
}

export const INIT_STATE: State = projectenAdapter.getInitialState({
  currentProjectId: undefined
});

export function reducer(
  state: State = INIT_STATE,
  { type, payload }: AllProjectenActions
) {
  switch (type) {
    case ProjectActionTypes.SET_CURRENT_PROJECT_ID:
      return {
        ...state,
        currentProjectId: payload
      };
    case ProjectActionTypes.LOAD_ALL_SUCCESS:
      return projectenAdapter.setAll(payload, {
        ...state,
        currentProjectId: undefined
      });
    case ProjectActionTypes.LOAD_SUCCESS:
      return projectenAdapter.addOne(payload, {
        ...state,
        currentProjectId: payload.documentId
      });
    case ProjectActionTypes.PATCH_SUCCESS:
      return projectenAdapter.updateOne(payload, {
        ...state,
        currentProjectId: undefined
      });
    case ProjectActionTypes.CREATE_SUCCESS:
      return projectenAdapter.addOne(payload, {
        ...state,
        currentProjectId: undefined
      });
    case ProjectActionTypes.FAILURE:
      return {
        ...state,
        error: payload
      };
    default:
      return state;
  }
}

export const getCurrentProjectId = (state: State) => state.currentProjectId;
