import { Gebruiker } from '@core-models-gebruiker';
import {
  All as AllGebruikerenActions,
  GebruikerActionTypes
} from '@core-store-gebruikers/gebruiker.actions';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';


export const gebruikersAdapter = createEntityAdapter<Gebruiker>({
  selectId: (gebruiker: Gebruiker) => gebruiker.uid,
  sortComparer: false
});

export interface State extends EntityState<Gebruiker> {
  currentGebruikerId?: string;
}

export const INIT_STATE: State = gebruikersAdapter.getInitialState({
  currentGebruikerId: undefined,
});

export function reducer(state: State = INIT_STATE, { type, payload }: AllGebruikerenActions) {
  switch (type) {
    case GebruikerActionTypes.SET_CURRENT_ORGANISATIE_ID:
      return {
        ...state,
        currentGebruikerId: payload
      };
    case GebruikerActionTypes.LOAD_ALL_SUCCESS:
      return gebruikersAdapter.setAll(payload, {
        ...state,
        currentGebruikerId: undefined
      });
    case GebruikerActionTypes.LOAD_SUCCESS:
      return gebruikersAdapter.addOne(payload, {
        ...state,
        currentGebruikerId: payload.documentId
      });
    case GebruikerActionTypes.PATCH_SUCCESS:
      return gebruikersAdapter.updateOne(payload, {
        ...state,
        currentGebruikerId: undefined
      });
    case GebruikerActionTypes.FAILURE:
      return {
        ...state,
        error: payload
      };
    default:
      return state;
  }
}

export const getCurrentGebruikerId = (state: State) => state.currentGebruikerId;
