import { Injectable } from '@angular/core';

import { Observable, of, from } from 'rxjs';
import { mergeMap, map, catchError, switchMap, concatMap } from 'rxjs/operators';

/* NgRx */
import { Action, select, UPDATE } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as gebruikerActions from '@core-store-gebruikers/gebruiker.actions';
import { Gebruiker } from '@core-models-gebruiker';
import { AngularFirestore } from '@angular/fire/firestore';
import * as snackbarActions from '@core-store-snackbar/snackbar.actions';

@Injectable()
export class GebruikerEffects {
  private collectionPath = 'gebruikers';

  constructor(private db: AngularFirestore, private actions$: Actions) {}

  @Effect()
  loadGebruikeren$: Observable<Action> = this.actions$.pipe(
    ofType(gebruikerActions.GebruikerActionTypes.LOAD_ALL),
    map((action: gebruikerActions.Load) => action),
    switchMap(action =>
      this.db.collection<Gebruiker>(
        this.collectionPath, fn => fn.where('organisatieId', '==', action.organisatieId)
        ).snapshotChanges().pipe(
          map(actions => {
            return actions.map(documentAction => {
              const data = documentAction.payload.doc.data();
              const documentId = documentAction.payload.doc.id;
              return { documentId, ...data };
            });
          }),
          map(gebruikers => new gebruikerActions.LoadAllSuccess(gebruikers)),
          catchError(err => of(new gebruikerActions.Failure({concern: 'LOAD', error: err})))
        )
    )
  );


  @Effect()
  loadGebruiker$: Observable<Action> = this.actions$.pipe(
    ofType(gebruikerActions.GebruikerActionTypes.LOAD),
    map((action: gebruikerActions.Load) => action),
    mergeMap(action => {
      return from(
        this.db.collection<Gebruiker[]>(
          this.collectionPath
          ).doc<Gebruiker>(action.payload).snapshotChanges().pipe(
        map(document => {
          const data = document.payload.data();
          const docId = document.payload.id;
          return { documentId: docId, ...data };
        }),
        map(gebruiker => new gebruikerActions.LoadSuccess(gebruiker)),
        catchError(err => of(new gebruikerActions.Failure({concern: 'LOAD', error: err})))
      ));
    })
  );

  @Effect()
  updateGebruiker$: Observable<Action> = this.actions$.pipe(
    ofType(gebruikerActions.GebruikerActionTypes.PATCH),
    map((action: gebruikerActions.Patch) => action),
    mergeMap(action => {
      const documentId = action.payload.uid;
      return from(
        this.db
          .collection<Gebruiker[]>(
            this.collectionPath
          )
          .doc<Gebruiker>(documentId)
          .update(action.payload)
          ).pipe(
            concatMap(() => [
              new gebruikerActions.PatchSuccess({
                id: action.payload.uid,
                changes: action.payload
               }),
              new snackbarActions.SnackbarOpen({
                message: 'Gebruiker bijgewerkt.',
                action: 'Success'})
              ]),
            catchError(err => of(
              new gebruikerActions.Failure({ concern: 'UPDATE', error: err}),
              new snackbarActions.SnackbarOpen({message: 'Gebruiker is niet bijgewerkt.Foutmelding: ' + err, action: 'Error'})
            ))
          );
    })
  );

  @Effect()
  deleteGebruiker$: Observable<Action> = this.actions$.pipe(
    ofType(gebruikerActions.GebruikerActionTypes.DELETE),
    map((action: gebruikerActions.Delete) => action),
    mergeMap(action => {
      return from(
        this.db
          .collection<Gebruiker[]>(
            this.collectionPath
          )
          .doc<Gebruiker>(action.payload).delete()
      ).pipe(
        concatMap(() => [
          new gebruikerActions.DeleteSuccess(action.payload),
          new snackbarActions.SnackbarOpen({
            message: 'Gebruiker is verwijderd.',
            action: 'Success'})
          ]),
        catchError(err => of(
          new gebruikerActions.Failure({concern: 'DELETE', error: err}),
          // tslint:disable-next-line:max-line-length
          new snackbarActions.SnackbarOpen({message: 'Er is een fout opgetreden. De gebruiker is niet verwijderd. Foutmelding: ' + err, action: 'Error'})
        ))
      );
    })
  );
}
