import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  defaultTablePageSize = 10;

  constructor() { }

  getBaseCollectionPath(organisatieId: string, featurePath: string = null) {
    if (featurePath) {
      return `organisaties/${organisatieId}/${featurePath}`;
    } else {
      return `organisaties/${organisatieId}`;
    }

  }
}
