import * as authActions from '@core-store-auth/auth.actions';

export function clearState(reducer) {
  return function(state, action) {
    if (action.type === authActions.AuthActionTypes.Logout) {
      console.log('[clean.state.reducer] clear state!');
      state = undefined;
    }

    return reducer(state, action);
  };
}
