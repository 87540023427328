import { DateRange } from './../../models/date-range';
import { Action } from '@ngrx/store';
import { Registratie } from '@core-models-registratie';
import { Update } from '@ngrx/entity/src/models';

export enum RegistratieActionTypes {
  LOAD_ALL = '[Registratie] Load All',
  LOAD_ALL_SUCCESS = '[Registratie] Load All Success',

  LOAD_ALL_PERSONAL = '[Registratie] Load All Personal',
  LOAD_ALL_PERSONAL_SUCCESS = '[Registratie] Load All Personal Success',

  LOAD = '[Registratie] Load',
  LOAD_SUCCESS = '[Registratie] Load Success',

  CREATE = '[Registratie] Create',
  CREATE_SUCCESS = '[Registratie] Create Success',

  PATCH = '[Registratie] Patch',
  PATCH_SUCCESS = '[Registratie] Patch Success',

  DELETE = '[Registratie] Delete',
  DELETE_SUCCESS = '[Registratie] Delete Success',

  FAILURE = '[Registratie] Failure',

  SET_CURRENT_REGISTRATIE_ID = '[Registratie] Set current registratieId'
}

export class SetCurrentRegistratieId implements Action {
  readonly type = RegistratieActionTypes.SET_CURRENT_REGISTRATIE_ID;
  constructor(public payload: string) {}
}

// export class ClearSelect implements Action {
//   readonly type = RegistratieActionTypes.CancelSelect;
// }

export class Load implements Action {
  readonly type = RegistratieActionTypes.LOAD;
  constructor(public payload: string, public organisatieId: string) {}
}

export class LoadSuccess implements Action {
  readonly type = RegistratieActionTypes.LOAD_SUCCESS;
  constructor(public payload: Registratie) {}
}

export class LoadAll implements Action {
  readonly type = RegistratieActionTypes.LOAD_ALL;
  constructor(
    public payload: DateRange,
    public klantId: string,
    public gebruikerId: string,
    public organisatieId: string
  ) {}
}

export class LoadAllSuccess implements Action {
  readonly type = RegistratieActionTypes.LOAD_ALL_SUCCESS;
  constructor(public payload: Registratie[]) {}
}

export class LoadAllPersonal implements Action {
  readonly type = RegistratieActionTypes.LOAD_ALL_PERSONAL;
  constructor(public payload: DateRange, public gebruikerId: string, public organisatieId: string) {}
}

export class LoadAllPersonalSuccess implements Action {
  readonly type = RegistratieActionTypes.LOAD_ALL_PERSONAL_SUCCESS;
  constructor(public payload: Registratie[]) {}
}

export class Create implements Action {
  readonly type = RegistratieActionTypes.CREATE;

  constructor(public payload: Registratie, public organisatieId: string) {}
}

export class CreateSuccess implements Action {
  readonly type = RegistratieActionTypes.CREATE_SUCCESS;

  constructor(public payload: Registratie) {}
}

export class Patch implements Action {
  readonly type = RegistratieActionTypes.PATCH;

  constructor(public payload: Registratie, public organisatieId: string) {}
}

export class PatchSuccess implements Action {
  readonly type = RegistratieActionTypes.PATCH_SUCCESS;

  constructor(public payload: Update<Registratie>) {}
}

export class Delete implements Action {
  readonly type = RegistratieActionTypes.DELETE;

  constructor(public payload: string, public organisatieId: string) {}
}

export class DeleteSuccess implements Action {
  readonly type = RegistratieActionTypes.DELETE_SUCCESS;
  constructor(public payload: any) {}
}

export class Failure implements Action {
  readonly type = RegistratieActionTypes.FAILURE;
  constructor(
    public payload: {
      concern: 'CREATE' | 'UPDATE' | 'LOAD' | 'DELETE';
      error: any;
    }
  ) {}
}

export type All =
  | SetCurrentRegistratieId
  | LoadAll
  | LoadAllPersonal
  | Load
  | Create
  | Patch
  | Delete
  | LoadAllSuccess
  | LoadAllPersonalSuccess
  | LoadSuccess
  | PatchSuccess
  | CreateSuccess
  | DeleteSuccess
  | Failure;
