import firebase from 'firebase/app';
import { Action } from '@ngrx/store';
import { Authenticate } from './authenticate';
import { Gebruiker } from '@core-models-gebruiker';

export enum AuthActionTypes {
  AuthenticateWithCredentials = '[Auth] Login With Credentials',
  AuthenticateWithGoogle = '[Auth] Login With Google',
  AuthenticateFailure = '[Auth] Login Failure',
  AuthenticateSuccess = '[Auth] Authenticate Success',
  NotAuthenticated = '[Auth] Not Authenticated',
  UserAuthenticated = '[Auth] User Authenticated',
  UserLoggedOut = '[Auth] User Logged Out Success',
  GetUser = '[Auth] Get User',
  Logout = '[Auth] Logout',
}


export class GetUser implements Action {
  readonly type = AuthActionTypes.GetUser;
}


export class AuthenticateWithCredentials implements Action {
  readonly type = AuthActionTypes.AuthenticateWithCredentials;

  constructor(public payload: Authenticate, public returnUrl: string) {}
}
export class AuthenticateWithGoogle implements Action {
  readonly type = AuthActionTypes.AuthenticateWithGoogle;

  constructor(public payload: string = null, public returnUrl: string) {}
}

export class AuthenticateSuccess implements Action {
  readonly type = AuthActionTypes.AuthenticateSuccess;

  constructor(public payload: firebase.UserInfo) {}
}

export class NotAuthenticated implements Action {
  readonly type = AuthActionTypes.NotAuthenticated;
}

export class AuthenticateFailure implements Action {
  readonly type = AuthActionTypes.AuthenticateFailure;

  constructor(public payload: any) {}
}

export class UserAuthenticated implements Action {
  readonly type = AuthActionTypes.UserAuthenticated;

  constructor(public payload: Gebruiker) {}
}

export class UserLoggedOut implements Action {
   readonly type = AuthActionTypes.UserLoggedOut;
}
export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}


export type AuthActions =
  | AuthenticateWithCredentials
  | AuthenticateWithGoogle
  | AuthenticateSuccess
  | AuthenticateFailure
  | NotAuthenticated
  | UserAuthenticated
  | UserLoggedOut
  | Logout;
