import { Injectable } from '@angular/core';

import { Observable, of, from } from 'rxjs';
import { mergeMap, map, catchError, switchMap, concatMap } from 'rxjs/operators';

/* NgRx */
import { Action, select, UPDATE } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as organisatieActions from '@core-store-organisaties/organisatie.actions';
import { Organisatie } from '@core-models-organisatie';
import { AngularFirestore } from '@angular/fire/firestore';
import * as snackbarActions from '@core-store-snackbar/snackbar.actions';

@Injectable()
export class OrganisatieEffects {
  private collectionPath = 'organisaties';

  constructor(private db: AngularFirestore, private actions$: Actions) {}

  @Effect()
  loadOrganisatieen$: Observable<Action> = this.actions$.pipe(
    ofType(organisatieActions.OrganisatieActionTypes.LOAD_ALL),
    map((action: organisatieActions.LoadAll) => action),
    switchMap(action =>
      this.db.collection<Organisatie>(
        this.collectionPath
      ).snapshotChanges().pipe(
          map(actions => {
            return actions.map(documentAction => {
              const data = documentAction.payload.doc.data();
              const documentId = documentAction.payload.doc.id;
              return { documentId, ...data };
            });
          }),
          map(organisaties => new organisatieActions.LoadAllSuccess(organisaties)),
          catchError(err => of(new organisatieActions.Failure({concern: 'LOAD', error: err})))
        )
    )
  );


  @Effect()
  loadOrganisatie$: Observable<Action> = this.actions$.pipe(
    ofType(organisatieActions.OrganisatieActionTypes.LOAD),
    map((action: organisatieActions.Load) => action),
    mergeMap(action => {
      return from(
        this.db.collection<Organisatie[]>(
          this.collectionPath
        ).doc<Organisatie>(action.payload).get()
      ).pipe(
        map(document => {
          const data = document.data() as Organisatie;
          const docId = document.id;
          return { documentId: docId, ...data };
        }),
        map(organisatie => new organisatieActions.LoadSuccess(organisatie)),
        catchError(err => of(new organisatieActions.Failure({concern: 'LOAD', error: err})))
      );
    })
  );

  @Effect()
  updateOrganisatie$: Observable<Action> = this.actions$.pipe(
    ofType(organisatieActions.OrganisatieActionTypes.PATCH),
    map((action: organisatieActions.Patch) => action),
    mergeMap(action => {
      const documentId = action.payload.documentId;
      return from(
        this.db
          .collection<Organisatie[]>(
            this.collectionPath
          )
          .doc<Organisatie>(documentId)
          .update(action.payload)
          ).pipe(
            concatMap(() => [
              new organisatieActions.PatchSuccess({
                id: action.payload.documentId,
                changes: action.payload
               }),
              new snackbarActions.SnackbarOpen({
                message: 'Organisatie bijgewerkt.',
                action: 'Success'})
              ]),
            catchError(err => of(
              new organisatieActions.Failure({ concern: 'UPDATE', error: err}),
              new snackbarActions.SnackbarOpen({message: 'Organisatie is niet bijgewerkt.Foutmelding: ' + err, action: 'Error'})
            ))
          );
    })
  );

  @Effect()
  createOrganisatie$: Observable<Action> = this.actions$.pipe(
    ofType(organisatieActions.OrganisatieActionTypes.CREATE),
    map((action: organisatieActions.Create) => action),
    mergeMap(action => {
      const documentId = this.db.createId();
      const newValue = {...action.payload, documentId};

      return from(
        this.db
          .collection<Organisatie[]>(
            this.collectionPath
          )
          .doc<Organisatie>(documentId)
          .set(newValue)
      ).pipe(
        concatMap(() => [
          new organisatieActions.CreateSuccess(newValue),
          new snackbarActions.SnackbarOpen({
            message: 'Nieuwe organisatie toegevoegd.',
            action: 'Success'})
          ]),
        catchError(err => of(
          new organisatieActions.Failure({concern: 'CREATE', error: err}),
          new snackbarActions.SnackbarOpen({message: 'Organisatie is niet verwijderd. Foutmelding: ' + err, action: 'Error'})
        ))
      );
    })
  );

  @Effect()
  deleteOrganisatie$: Observable<Action> = this.actions$.pipe(
    ofType(organisatieActions.OrganisatieActionTypes.DELETE),
    map((action: organisatieActions.Delete) => action),
    mergeMap(action => {
      return from(
        this.db
          .collection<Organisatie[]>(
            this.collectionPath
          )
           .doc<Organisatie>(action.payload).delete()
      ).pipe(
        concatMap(() => [
          new organisatieActions.DeleteSuccess(action.payload),
          new snackbarActions.SnackbarOpen({
            message: 'Organisatie is verwijderd.',
            action: 'Success'})
          ]),
        catchError(err => of(
          new organisatieActions.Failure({concern: 'DELETE', error: err}),
          // tslint:disable-next-line:max-line-length
          new snackbarActions.SnackbarOpen({message: 'Er is een fout opgetreden. De organisatie is niet verwijderd. Foutmelding: ' + err, action: 'Error'})
        ))
      );
    })
  );
}
