import { Action } from '@ngrx/store';
import { UrenPerMedewerker } from '@core-models/overzicht';

export enum OverzichtActionTypes {
  LOAD_UREN_PER_MEDEWERKER = '[Overzicht] Load Uren Per Medewerker',
  LOAD_UREN_PER_MEDEWERKER_SUCCESS = '[Overzicht] Load Uren Per Medewerker Success',

  FAILURE = '[Overzicht] Failure'
}

export class LoadUrenPerMedewerker implements Action {
  readonly type = OverzichtActionTypes.LOAD_UREN_PER_MEDEWERKER;
  constructor(public payload: number, public organisatieId: string) {
  }
}

export class LoadUrenPerMedewerkerSuccess implements Action {
  readonly type = OverzichtActionTypes.LOAD_UREN_PER_MEDEWERKER_SUCCESS;
  constructor(public payload: UrenPerMedewerker[]) {}
}

export class Failure implements Action {
  readonly type = OverzichtActionTypes.FAILURE;
  constructor(public payload: { concern: 'LOAD_UREN_PER_MEDEWERKER' | 'ANDERE_ACTION'; error: any }) {}
}

export type All =
  | LoadUrenPerMedewerker
  | LoadUrenPerMedewerkerSuccess
  | Failure;
