import { AppSettingsService } from './../../appsettings/appsettings.service';
import { Injectable } from '@angular/core';

import { Observable, of, from } from 'rxjs';
import { mergeMap, map, catchError, switchMap, concatMap } from 'rxjs/operators';

/* NgRx */
import { Action, select, UPDATE } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as klantActions from '@core-store-klanten/klant.actions';
import { Klant } from '@core-models-klant';
import { AngularFirestore } from '@angular/fire/firestore';
import * as snackbarActions from '@core-store-snackbar/snackbar.actions';

@Injectable()
export class KlantEffects {
  private collectionPath = 'klanten';

  constructor(private db: AngularFirestore, private actions$: Actions, private appSettings: AppSettingsService) { }

  @Effect()
  loadKlanten$: Observable<Action> = this.actions$.pipe(
    ofType(klantActions.KlantActionTypes.LOAD_ALL),
    map((action: klantActions.LoadAll) => action),
    switchMap(action =>
      this.db.collection<Klant>(
        this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath)
        ).snapshotChanges().pipe(
          map(actions => {
            return actions.map(documentAction => {
              const data = documentAction.payload.doc.data();
              const documentId = documentAction.payload.doc.id;
              return { documentId, ...data };
            });
          }),
          map(klanten => new klantActions.LoadAllSuccess(klanten)),
          catchError(err => of(new klantActions.Failure({concern: 'LOAD', error: err})))
        )
    )
  );


  @Effect()
  loadKlant$: Observable<Action> = this.actions$.pipe(
    ofType(klantActions.KlantActionTypes.LOAD),
    map((action: klantActions.Load) => action),
    mergeMap(action => {
      return from(
        this.db.collection<Klant[]>(
          this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath)
          ).doc<Klant>(action.payload).get()
      ).pipe(
        map(document => {
          const data = document.data() as Klant;
          const docId = document.id;
          return { documentId: docId, ...data };
        }),
        map(klant => new klantActions.LoadSuccess(klant)),
        catchError(err => of(new klantActions.Failure({concern: 'LOAD', error: err})))
      );
    })
  );

  @Effect()
  updateKlant$: Observable<Action> = this.actions$.pipe(
    ofType(klantActions.KlantActionTypes.PATCH),
    map((action: klantActions.Patch) => action),
    mergeMap(action => {
      const documentId = action.payload.documentId;
      return from(
        this.db
          .collection<Klant[]>(
            this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath)
            )
          .doc<Klant>(documentId)
          .update(action.payload)
          ).pipe(
            concatMap(() => [
              new klantActions.PatchSuccess({
                id: action.payload.documentId,
                changes: action.payload
               }),
              new snackbarActions.SnackbarOpen({
                message: 'Klant bijgewerkt.',
                action: 'Success'})
              ]),
            catchError(err => of(
              new klantActions.Failure({ concern: 'UPDATE', error: err}),
              new snackbarActions.SnackbarOpen({message: 'Klant is niet bijgewerkt.Foutmelding: ' + err, action: 'Error'})
            ))
          );
    })
  );

  @Effect()
  createKlant$: Observable<Action> = this.actions$.pipe(
    ofType(klantActions.KlantActionTypes.CREATE),
    map((action: klantActions.Create) => action),
    mergeMap(action => {
      const documentId = this.db.createId();
      const newValue = {...action.payload, documentId};
    return from(
        this.db
          .collection<Klant[]>(
            this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath)
          )
          .doc<Klant>(documentId)
          .set(newValue)
      ).pipe(
        concatMap(() => [
          new klantActions.CreateSuccess(newValue),
          new snackbarActions.SnackbarOpen({
            message: 'Nieuwe klant toegevoegd.',
            action: 'Success'})
          ]),
        catchError(err => of(
          new klantActions.Failure({concern: 'CREATE', error: err}),
          new snackbarActions.SnackbarOpen({message: 'Klant is niet verwijderd. Foutmelding: ' + err, action: 'Error'})
        ))
      );
    })
  );

  @Effect()
  deleteKlant$: Observable<Action> = this.actions$.pipe(
    ofType(klantActions.KlantActionTypes.DELETE),
    map((action: klantActions.Delete) => action),
    mergeMap(action => {
      return from(
        this.db
          .collection<Klant[]>(
            this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath)
            )
           .doc<Klant>(action.payload).delete()
      ).pipe(
        concatMap(() => [
          new klantActions.DeleteSuccess(action.payload),
          new snackbarActions.SnackbarOpen({
            message: 'Klant is verwijderd.',
            action: 'Success'})
          ]),
        catchError(err => of(
          new klantActions.Failure({concern: 'DELETE', error: err}),
          // tslint:disable-next-line:max-line-length
          new snackbarActions.SnackbarOpen({message: 'Er is een fout opgetreden. De klant is niet verwijderd. Foutmelding: ' + err, action: 'Error'})
        ))
      );
    })
  );
}
