import { Registratie } from '@core-models-registratie';
import {
  All as AllRegistratieActions,
  RegistratieActionTypes
} from '@core-store-registratie/registratie.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const mijnRegistratiesAdapter = createEntityAdapter<Registratie>({
  selectId: (registratie: Registratie) => registratie.documentId,
  sortComparer: false
});

export const registratiesAdapter = createEntityAdapter<Registratie>({
  selectId: (registratie: Registratie) => registratie.documentId,
  sortComparer: false
});

export interface MijnRegistratiesState extends EntityState<Registratie> {}

export interface RegistratiesState extends EntityState<Registratie> {}

export interface State {
  mijnRegistraties: MijnRegistratiesState;
  registraties: RegistratiesState;
}

export const INIT_STATE: State = {
  mijnRegistraties: mijnRegistratiesAdapter.getInitialState({}),
  registraties: registratiesAdapter.getInitialState({})
};

export function reducer(
  state: State = INIT_STATE,
  { type, payload }: AllRegistratieActions
) {
  switch (type) {
    case RegistratieActionTypes.SET_CURRENT_REGISTRATIE_ID:
      return {
        ...state,
        currentRegistratieId: payload
      };
    case RegistratieActionTypes.LOAD_ALL_SUCCESS:
      return {
        mijnRegistraties: state.mijnRegistraties,
        registraties: registratiesAdapter.setAll(payload, {
          ...state.registraties
        })
      };
    case RegistratieActionTypes.LOAD_SUCCESS:
      return {
        mijnRegistraties: mijnRegistratiesAdapter.addOne(payload, {
          ...state.mijnRegistraties
        }),
        registraties: registratiesAdapter.addOne(payload, {
          ...state.registraties
        })
      };
    case RegistratieActionTypes.PATCH_SUCCESS:
      return {
        mijnRegistraties: mijnRegistratiesAdapter.updateOne(payload, {
          ...state.mijnRegistraties
        }),
        registraties: registratiesAdapter.updateOne(payload, {
          ...state.registraties
        })
      };
    case RegistratieActionTypes.CREATE_SUCCESS:
      console.log(`CREATE_SUCCESS`,payload);
      return {
        mijnRegistraties: mijnRegistratiesAdapter.addOne(payload, {
          ...state.mijnRegistraties
        }),
        registraties: registratiesAdapter.addOne(payload, {
          ...state.registraties
        })
      };
    case RegistratieActionTypes.LOAD_ALL_PERSONAL_SUCCESS:
      return {
        mijnRegistraties: mijnRegistratiesAdapter.setAll(payload, {
          ...state.mijnRegistraties
        }),
        registraties: state.registraties
      };
    case RegistratieActionTypes.FAILURE:
      return {
        ...state,
        error: payload
      };
    default:
      return state;
  }
}
