import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '@core-store-auth/auth.reducer';
import * as authSelectors from '@core-store-auth/index';
import * as authActions from '@core-store-auth/auth.actions';
import { switchMap, tap } from 'rxjs/operators';
import { BaseComponent } from '../core/base/base.component';

@Component({
  selector: 'eci-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
  username: string;
  password: string;
  returnUrl = '';

  constructor(
    private router: Router,
    private store: Store<fromAuth.AuthenticationState>,
    private route: ActivatedRoute
  ) {
    super();
  }

  signInWithGoogle() {
    this.store.dispatch(new authActions.AuthenticateWithGoogle(null, this.returnUrl));
  }

  signInWithEmail() {
    if (this.username && this.password) {
      this.store.dispatch(
        new authActions.AuthenticateWithCredentials({ email: this.username, password: this.password }, this.returnUrl)
      );
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        const logoff = params['logoff'] || false;
        this.store.dispatch(new authActions.Logout());
        this.returnUrl = params['returnUrl'] || '/dashboard';
      })
    );

    this.subscriptions.push(
      this.store
        .pipe(
          select(authSelectors.selectIsAuthenticated),
          tap(isAuthenticated => {
            if (isAuthenticated) {
              this.router.navigate([this.returnUrl]);
            }
          })
        )
        .subscribe()
    );
  }
}
