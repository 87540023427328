import { Action } from '@ngrx/store';
import { Klant } from '@core-models-klant';
import { Update } from '@ngrx/entity/src/models';

export enum KlantActionTypes {
  LOAD_ALL = '[Klant] Load All',
  LOAD_ALL_SUCCESS = '[Klant] Load All Success',

  LOAD = '[Klant] Load',
  LOAD_SUCCESS = '[Klant] Load Success',

  CREATE = '[Klant] Create',
  CREATE_SUCCESS = '[Klant] Create Success',

  PATCH = '[Klant] Patch',
  PATCH_SUCCESS = '[Klant] Patch Success',

  DELETE = '[Klant] Delete',
  DELETE_SUCCESS = '[Klant] Delete Success',

  FAILURE = '[Klant] Failure',

  SET_CURRENT_KLANT_ID = '[Klant] Set current klantId'
}

export class SetCurrentKlantId implements Action {
  readonly type = KlantActionTypes.SET_CURRENT_KLANT_ID;
  constructor(public payload: string) {}
}

// export class ClearSelect implements Action {
//   readonly type = KlantActionTypes.CancelSelect;
// }

export class Load implements Action {
  readonly type = KlantActionTypes.LOAD;
  constructor(public payload: string, public organisatieId: string) {}
}

export class LoadSuccess implements Action {
  readonly type = KlantActionTypes.LOAD_SUCCESS;
  constructor(public payload: Klant) {}
}

export class LoadAll implements Action {
  readonly type = KlantActionTypes.LOAD_ALL;
  constructor(public payload = null, public organisatieId: string) {
    console.log('[klant.actions] LoadAll]');
  }
}

export class LoadAllSuccess implements Action {
  readonly type = KlantActionTypes.LOAD_ALL_SUCCESS;
  constructor(public payload: Klant[]) {}
}

export class Create implements Action {
  readonly type = KlantActionTypes.CREATE;

  constructor(public payload: Klant, public organisatieId: string) {}
}

export class CreateSuccess implements Action {
  readonly type = KlantActionTypes.CREATE_SUCCESS;

  constructor(public payload: Klant) {}
}

export class Patch implements Action {
  readonly type = KlantActionTypes.PATCH;

  constructor(public payload: Klant, public organisatieId: string) {}
}

export class PatchSuccess implements Action {
  readonly type = KlantActionTypes.PATCH_SUCCESS;

  constructor(public payload: Update<Klant>) {}
}

export class Delete implements Action {
  readonly type = KlantActionTypes.DELETE;

  constructor(public payload: string, public organisatieId: string) {}
}

export class DeleteSuccess implements Action {
  readonly type = KlantActionTypes.DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export class Failure implements Action {
  readonly type = KlantActionTypes.FAILURE;
  constructor(public payload: { concern: 'CREATE' | 'UPDATE' | 'LOAD' | 'DELETE'; error: any }) {}
}

export type All =
  | SetCurrentKlantId
  | LoadAll
  | Load
  | Create
  | Patch
  | Delete
  | LoadAllSuccess
  | LoadSuccess
  | PatchSuccess
  | CreateSuccess
  | DeleteSuccess
  | Failure;
