import { Klant } from '@core-models-klant';
import {
  All as AllKlantenActions,
  KlantActionTypes
} from '@core-store-klanten/klant.actions';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';

export const klantenAdapter = createEntityAdapter<Klant>({
  selectId: (klant: Klant) => klant.documentId,
  sortComparer: false
});

export interface State extends EntityState<Klant> {
  currentKlantId?: string;
}

export const INIT_STATE: State = klantenAdapter.getInitialState({
  currentKlantId: undefined
});

export function reducer(state: State = INIT_STATE, { type, payload }: AllKlantenActions
) {
  switch (type) {
    case KlantActionTypes.SET_CURRENT_KLANT_ID:
      return {
        ...state,
        currentKlantId: payload
      };
    case KlantActionTypes.LOAD_ALL_SUCCESS:
      console.log('[klant.reducer] LOAD_ALL_SUCCESS]');
      return klantenAdapter.setAll(payload, {
        ...state,
        currentKlantId: undefined
      });
    case KlantActionTypes.LOAD_SUCCESS:
      return klantenAdapter.addOne(payload, {
        ...state,
        currentKlantId: payload.documentId
      });
    case KlantActionTypes.PATCH_SUCCESS:
      return klantenAdapter.updateOne(payload, {
        ...state,
        currentKlantId: undefined
      });
    case KlantActionTypes.CREATE_SUCCESS:
      return klantenAdapter.addOne(payload, {
        ...state,
        currentKlantId: undefined
      });
    case KlantActionTypes.FAILURE:
       return {
         ...state,
         error: payload
       };
    default:
      return state;
  }
}

export const getCurrentKlantId = (state: State) => state.currentKlantId;
