import { GebruikerProject } from '@core-models/gebruiker-project';
import {
  All as AllGebruikerProjectenActions,
  GebruikerProjectenActionTypes
} from '@core-store-gebruiker-projecten/gebruiker-projecten.actions';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';


export const gebruikerprojectsAdapter = createEntityAdapter<GebruikerProject>({
  selectId: (gebruikerproject: GebruikerProject) => gebruikerproject.documentId,
  sortComparer: false
});

export interface State extends EntityState<GebruikerProject> {
}

export const INIT_STATE: State = gebruikerprojectsAdapter.getInitialState({
});

export function reducer(state: State = INIT_STATE, { type, payload }: AllGebruikerProjectenActions) {
  switch (type) {
    case GebruikerProjectenActionTypes.LOAD_ALL_SUCCESS:
      return gebruikerprojectsAdapter.setAll(payload as GebruikerProject[], {
        ...state
      });
    case GebruikerProjectenActionTypes.ADD_SUCCESS:
      return gebruikerprojectsAdapter.addOne(payload as GebruikerProject, {
        ...state
      });
    case GebruikerProjectenActionTypes.REMOVE_SUCCESS:
      return gebruikerprojectsAdapter.removeOne(payload as string, {
        ...state
      });
    case GebruikerProjectenActionTypes.FAILURE:
      return {
        ...state,
        error: payload
      };
    default:
      return state;
  }
}
