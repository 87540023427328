import { Action } from '@ngrx/store';
import { Gebruiker } from '@core-models-gebruiker';
import { Update } from '@ngrx/entity/src/models';
import { GebruikerProject } from '@core-models/gebruiker-project';

export enum GebruikerActionTypes {
  LOAD_ALL = '[Gebruiker] Load All',
  LOAD_ALL_SUCCESS = '[Gebruiker] Load All Success',

  LOAD = '[Gebruiker] Load',
  LOAD_SUCCESS = '[Gebruiker] Load Success',

  CREATE = '[Gebruiker] Create',
  CREATE_SUCCESS = '[Gebruiker] Create Success',

  PATCH = '[Gebruiker] Patch',
  PATCH_SUCCESS = '[Gebruiker] Patch Success',

  DELETE = '[Gebruiker] Delete',
  DELETE_SUCCESS = '[Gebruiker] Delete Success',

  FAILURE = '[Gebruiker] Failure',

  SET_CURRENT_ORGANISATIE_ID = '[Gebruiker] Set current gebruikerId'
}

export class SetCurrentGebruikerId implements Action {
  readonly type = GebruikerActionTypes.SET_CURRENT_ORGANISATIE_ID;
  constructor(public payload: string) {}
}

export class Load implements Action {
  readonly type = GebruikerActionTypes.LOAD;
  constructor(public payload: string, public organisatieId: string) {}
}

export class LoadSuccess implements Action {
  readonly type = GebruikerActionTypes.LOAD_SUCCESS;
  constructor(public payload: Gebruiker) {}
}

export class LoadAll implements Action {
  readonly type = GebruikerActionTypes.LOAD_ALL;
  constructor(public payload = null, public organisatieId: string) {}
}

export class LoadAllSuccess implements Action {
  readonly type = GebruikerActionTypes.LOAD_ALL_SUCCESS;
  constructor(public payload: Gebruiker[]) {}
}

// export class Create implements Action {
//   readonly type = GebruikerActionTypes.CREATE;

//   constructor(public payload: Gebruiker) {}
// }

// export class CreateSuccess implements Action {
//   readonly type = GebruikerActionTypes.CREATE_SUCCESS;

//   constructor(public payload: Gebruiker) {}
// }

export class Patch implements Action {
  readonly type = GebruikerActionTypes.PATCH;

  constructor(public payload: Gebruiker, public organisatieId: string) {}
}

export class PatchSuccess implements Action {
  readonly type = GebruikerActionTypes.PATCH_SUCCESS;

  constructor(public payload: Update<Gebruiker>) {}
}

export class Delete implements Action {
  readonly type = GebruikerActionTypes.DELETE;

  constructor(public payload: string) {}
}

export class DeleteSuccess implements Action {
  readonly type = GebruikerActionTypes.DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export class Failure implements Action {
  readonly type = GebruikerActionTypes.FAILURE;
  constructor(public payload: { concern: 'CREATE' | 'UPDATE' | 'LOAD' | 'DELETE'; error: any }) {}
}

export type All =
  | SetCurrentGebruikerId
  | LoadAll
  | Load
  | Patch
  | Delete
  | LoadAllSuccess
  | LoadSuccess
  | PatchSuccess
  | DeleteSuccess
  | Failure;
