import { OverzichtEffects } from './store/overzichten/overzicht.effects';
import { AppSettingsService } from './appsettings/appsettings.service';

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

/* NGRX */
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromKlanten from '@core-store-klanten/klant.reducer';
import * as fromOrganisaties from '@core-store-organisaties/organisatie.reducer';
import { KlantEffects } from '@core-store-klanten/klant.effects';
import { OrganisatieEffects } from '@core-store-organisaties/organisatie.effects';
import { AuthEffects } from '@core-store-auth/auth.effects';
import * as fromAuth from '@core-store-auth/auth.reducer';
import * as fromGebruikers from '@core-store-gebruikers/gebruiker.reducer';
import * as fromProjecten from '@core-store-projecten/project.reducer';
import { GebruikerEffects } from '@core-store-gebruikers/gebruiker.effects';
import { ProjectEffects } from '@core-store-projecten/project.effects';
import { AuthService } from '@core-store-auth/auth.service';
import { AuthGuard } from '@core-store-auth/auth.guard';
import * as fromGebruikerProjecten from '@core-store-gebruiker-projecten/gebruiker-projecten.reducer';
import { GebruikerProjectenEffecten } from '@core-store-gebruiker-projecten/gebruiker-projecten.effects';
import * as fromRegistraties from '@core-store-registratie/registratie.reducer';
import { RegistratieEffects } from '@core-store-registratie/registratie.effects';
import * as fromOverzicht from '../core/store/overzichten/overzicht.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('organisaties', fromOrganisaties.reducer),
    StoreModule.forFeature('klanten', fromKlanten.reducer),
    StoreModule.forFeature('authentication', fromAuth.reducer),
    StoreModule.forFeature('gebruikers', fromGebruikers.reducer),
    StoreModule.forFeature('projecten', fromProjecten.reducer),
    StoreModule.forFeature('gebruiker-projecten', fromGebruikerProjecten.reducer),
    StoreModule.forFeature('registratie', fromRegistraties.reducer),
    StoreModule.forFeature('overzicht', fromOverzicht.reducer),
    EffectsModule.forFeature([
      OrganisatieEffects,
      KlantEffects,
      AuthEffects,
      GebruikerEffects,
      ProjectEffects,
      GebruikerProjectenEffecten,
      RegistratieEffects,
      OverzichtEffects
    ])
  ],
  providers: [AppSettingsService, AuthService, AuthGuard]
})
export class CoreModule {

  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

}
