import { LayoutComponent } from './layout/layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './core/store/auth/auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/admin/admin.module').then(m => m. AdminModule)
      },
      {
        path: 'overzichten',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/overzichten/overzichten.module').then(m => m.OverzichtenModule)
      },
      {
        path: 'gebruiker-projecten',
        canActivate: [AuthGuard],
        loadChildren:
          () => import('./features/gebruiker-projecten/gebruiker-projecten.module').then(m => m.GebruikerProjectenModule)
      },
      {
        path: 'gebruikers',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/gebruikers/gebruiker.module').then(m => m.GebruikerModule)
      },
      {
        path: 'klanten',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/klanten/klant.module').then(m => m.KlantModule)
      },
      {
        path: 'organisaties',
        canActivate: [AuthGuard],
        loadChildren:
          () => import('./features/organisaties/organisatie.module').then(m => m.OrganisatieModule)
      },
      {
        path: 'projecten',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/projecten/project.module').then(m => m.ProjectModule)
      },
      {
        path: 'registratie-form',
        canActivate: [AuthGuard],
        loadChildren:
          () => import('./features/registratie/registratie-form/registratie-form.module').then(m => m.RegistratieFormModule)
      },
      {
        path: 'registratie-table',
        canActivate: [AuthGuard],
        loadChildren:
          () => import('./features/registratie/registratie-table/registratie-table.module').then(m => m.RegistratieTableModule)
      },
      {
        path: 'registratie-dag',
        canActivate: [AuthGuard],
        loadChildren:
          () => import('./features/registratie/registratie-dag/registratie-dag.module').then(m => m.RegistratieDagModule)
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
