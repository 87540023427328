<div fxLayout fxLayoutAlign="center center" class="container">

  <mat-card class="example-card">
    <form (ngSubmit)="signInWithEmail()">
      <mat-card-header>
        <mat-card-title>Login</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <table class="example-full-width" cellspacing="0">
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Email" [(ngModel)]="username" name="username" autocomplete="email"
                  required>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Password" [(ngModel)]="password" type="password" name="password"
                  autocomplete="current-password" required>
              </mat-form-field>
            </td>
          </tr>
        </table>

        <!-- <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner> -->
      </mat-card-content>
      <mat-card-actions>
        <button type="submit" mat-raised-button (click)="signInWithEmail()" color="primary">Login</button>
        <button type="button" mat-raised-button (click)="signInWithGoogle()" color="primary">Sign in with
          Google</button>
      </mat-card-actions>
    </form>
  </mat-card>

</div>