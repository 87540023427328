import { Gebruiker } from './../../models/gebruiker';
import { AuthActions, AuthActionTypes } from './auth.actions';

export interface AuthenticationState {
  isAuthenticated: boolean;
  user: Gebruiker;
  error: string;
  returnUrl: string;
}

export const initialState: AuthenticationState = {
  isAuthenticated: false,
  user: {
    displayName: null,
    email: null,
    phoneNumber: null,
    photoURL: null,
    providerId: '',
    uid: '',
    rollen: { gast: true },
    organisatieId: null
  },
  returnUrl: null,
  error: null,
};

export function reducer(
  state = initialState,
  action: AuthActions
): AuthenticationState {
  switch (action.type) {
    case AuthActionTypes.AuthenticateWithCredentials || AuthActionTypes.AuthenticateWithGoogle:
      return { ...state, returnUrl: action.returnUrl };
    case AuthActionTypes.UserAuthenticated:
      console.log('reducer:' + JSON.stringify(action.payload));
      return { ...state, user: action.payload };
    //   case AuthActionTypes.LoginFailure:
    //   return { ...state, error: action.payload };
    case AuthActionTypes.AuthenticateSuccess:
      return { ...state, isAuthenticated: true };
    case AuthActionTypes.AuthenticateFailure:
      console.log(`Authentication Failure: ${action.payload}`);
      return state;
    case AuthActionTypes.NotAuthenticated:
      return initialState;
    default:
      return state;
  }
}
