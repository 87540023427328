import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { Gebruiker } from '@core-models-gebruiker';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class AuthService {
  public gebruiker$: Observable<Gebruiker>;

  constructor(private _firebaseAuth: AngularFireAuth, private router: Router, private db: AngularFirestore) {
    this.gebruiker$ = this._firebaseAuth.authState.pipe(
      tap(user => console.info('authState changed', user)),
      switchMap(user => {
        if (user) {
          return this.db.doc<Gebruiker>(`gebruikers/${user.uid}`).valueChanges();
        } else {
          const gebruiker: Gebruiker = {
            displayName: '',
            email: '',
            organisatieId: null,
            phoneNumber: null,
            photoURL: null,
            providerId: null,
            rollen: {},
            uid: ''
          };
          return of(gebruiker);
        }
      })
    );
  }

  get authState$() {
    return this._firebaseAuth.authState;
  }

  signInWithTwitter() {
    return this._firebaseAuth.signInWithPopup(new firebase.auth.TwitterAuthProvider()).then(credential => {
      return this.updateGebruikerData(credential.user);
    });
  }

  signInWithFacebook() {
    return this._firebaseAuth.signInWithPopup(new firebase.auth.FacebookAuthProvider()).then(credential => {
      return this.updateGebruikerData(credential.user);
    });
  }

  signInWithGoogle() {
    return this._firebaseAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(credential => {
      return this.updateGebruikerData(credential.user);
    });
  }

  signInWithGithub() {
    return this._firebaseAuth.signInWithPopup(new firebase.auth.GithubAuthProvider()).then(credential => {
      return this.updateGebruikerData(credential.user);
    });
  }

  signInRegular(email, password) {
    // const credential = firebase.auth.EmailAuthProvider.credential( email, password );

    return this._firebaseAuth.signInWithEmailAndPassword(email, password).then(credential => {
      return this.updateGebruikerData(credential.user);
    });
  }

  logout() {
    return this._firebaseAuth.signOut();
  }

  updateGebruikerData(user: firebase.User) {
    console.log('updateGebruikerData: ' + user.uid);
    const userRef = this.db.doc<Gebruiker>(`gebruikers/${user.uid}`);
    const data = {
      uid: user.uid,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      providerId: user.providerId,
      email: user.email,
      rollen: {
        gast: true
      }
    };

    if (user.displayName && user.displayName !== '') {
      (data as Gebruiker).displayName = user.displayName;
    }

    const socialebenadering = 'wLHGQK7AGjvHswGzUTtz';
    const educared = 'saCRMriZ8EQh1TjIvfVs';
    const hostname = window.location.hostname;
    
    if (hostname.includes('educared')) {
      (data as Gebruiker).organisatieId = educared;
    }

    if (hostname.includes('socialebenadering')) {
      (data as Gebruiker).organisatieId = socialebenadering;
    }

    if (hostname.includes('taasc')) {
      (data as Gebruiker).organisatieId = 'taasc';
    }

    return userRef.set(data as Gebruiker, { merge: true }).then(() => user);
  }
}
