import { Injectable } from '@angular/core';

import { Observable, of, from } from 'rxjs';
import { mergeMap, map, catchError, switchMap, concatMap, tap } from 'rxjs/operators';

/* NgRx */
import { Action, select, UPDATE } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as gebruikerProjectenActions from '@core-store-gebruiker-projecten/gebruiker-projecten.actions';
import { GebruikerProject } from '@core-models/gebruiker-project';
import { AngularFirestore } from '@angular/fire/firestore';
import * as snackbarActions from '@core-store-snackbar/snackbar.actions';

@Injectable()
export class GebruikerProjectenEffecten {
    private collectionPath = 'gebruikers';

    constructor(private db: AngularFirestore, private actions$: Actions) {}


    @Effect()
    loadAll$: Observable<Action> = this.actions$.pipe(
      ofType(gebruikerProjectenActions.GebruikerProjectenActionTypes.LOAD_ALL),
      map((action: gebruikerProjectenActions.LoadAll) => action),
      tap(a => console.log('[GebruikerProjectenEffecten] loadAll')),
      switchMap(action =>
        this.db.collection<GebruikerProject>(
          this.collectionPath + `/${action.payload}/projecten`
          ).snapshotChanges().pipe(
            map(actions => {
              return actions.map(documentAction => {
                const data = documentAction.payload.doc.data();
                const documentId = documentAction.payload.doc.id;
                return { documentId, ...data };
              });
            }),
            map(gebruikerProjecten => new gebruikerProjectenActions.LoadAllSuccess(gebruikerProjecten)),
            catchError(err => of(new gebruikerProjectenActions.Failure({concern: 'LOAD', error: err})))
          )
      )
    );

    @Effect()
    add$: Observable<Action> = this.actions$.pipe(
      ofType(gebruikerProjectenActions.GebruikerProjectenActionTypes.ADD),
      map((action: gebruikerProjectenActions.Add) => action),
      switchMap(action => {
        const gebruikerId = action.gebruikerId;
        const gebruikerProjectDto = action.payload;

        const gebruikerProject: GebruikerProject = {
          documentId: this.db.createId(),
          projectId: gebruikerProjectDto.projectId,
          projectNaam: gebruikerProjectDto.projectNaam,
          klantId: gebruikerProjectDto.klantId,
          klantNaam: gebruikerProjectDto.klantNaam,
        };

        return from(
          this.db
            .collection<GebruikerProject>(
              this.collectionPath + `/${gebruikerId}/projecten`
            )
            .doc<GebruikerProject>(gebruikerProject.documentId)
            .set(gebruikerProject)
            ).pipe(
              concatMap(() => [
                new gebruikerProjectenActions.AddSuccess({
                  documentId: gebruikerProject.documentId,
                  projectId: gebruikerProject.projectId,
                  projectNaam: gebruikerProject.projectNaam,
                  klantId: gebruikerProject.klantId,
                  klantNaam: gebruikerProject.klantNaam
                 }),
                new snackbarActions.SnackbarOpen({
                  message: 'Project toegevoegd.',
                  action: 'Success'})
                ]),
              catchError(err => of(
                new gebruikerProjectenActions.Failure({ concern: 'UPDATE', error: err}),
                new snackbarActions.SnackbarOpen({message: 'Gebruiker is niet bijgewerkt.Foutmelding: ' + err, action: 'Error'})
              ))
            );
      })
    );

    @Effect()
    remove$: Observable<Action> = this.actions$.pipe(
      ofType(gebruikerProjectenActions.GebruikerProjectenActionTypes.REMOVE),
      map((action: gebruikerProjectenActions.Remove) => action),
      switchMap(action => {
        const documentId = action.payload;
        const gebruikerId = action.gebruikerId;
        return from(
          this.db
            .collection<GebruikerProject>(
              this.collectionPath + `/${gebruikerId}/projecten`
            )
            .doc<GebruikerProject>(documentId).delete()
            ).pipe(
              concatMap(() => [
                new gebruikerProjectenActions.RemoveSuccess(action.payload),
                new snackbarActions.SnackbarOpen({
                  message: 'Project is uit je overzicht gehaald.',
                  action: 'Success'})
                ]),
              catchError(err => of(
                new gebruikerProjectenActions.Failure({ concern: 'UPDATE', error: err}),
                new snackbarActions.SnackbarOpen({message: 'Gebruiker is niet bijgewerkt.Foutmelding: ' + err, action: 'Error'})
              ))
            );
      })
    );

}
