import { MaterialLayoutModule } from '../core/material/material.layout.module';
import { MaterialButtonsModule } from '../core/material/material.buttons.module';
import { MaterialNavigationModule } from '../core/material/material.navigation.module';
import { LayoutComponent } from './layout.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialButtonsModule,
    MaterialNavigationModule,
    MaterialLayoutModule,
    FlexLayoutModule
  ]
})
export class LayoutModule { }
