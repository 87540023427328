import { GebruikerProjectDto } from './../../models/gebruiker-project';
import { Action } from '@ngrx/store';
import { Gebruiker } from '@core-models-gebruiker';
import { Update } from '@ngrx/entity/src/models';
import { GebruikerProject } from '@core-models/gebruiker-project';

export enum GebruikerProjectenActionTypes {
  LOAD_ALL = '[Gebruiker Projecten] Load All',
  LOAD_ALL_SUCCESS = '[Gebruiker Projecten] Load All Success',

  LOAD = '[Gebruiker Projecten] Load',
  LOAD_SUCCESS = '[Gebruiker Projecten] Load Success',

  ADD = '[Gebruiker Projecten] Add',
  ADD_SUCCESS = '[Gebruiker Projecten] Add Success',

  REMOVE = '[Gebruiker Projecten] Remove',
  REMOVE_SUCCESS = '[Gebruiker Projecten] Remove Success',

  FAILURE = '[Gebruiker Projecten] Failure',
}

export class LoadAll implements Action {
    readonly type = GebruikerProjectenActionTypes.LOAD_ALL;
    constructor(public payload: string) {}
}

export class LoadAllSuccess implements Action {
    readonly type = GebruikerProjectenActionTypes.LOAD_ALL_SUCCESS;
    constructor(public payload: GebruikerProject[]) {}
}


export class Add implements Action {
  readonly type = GebruikerProjectenActionTypes.ADD;
  constructor(public payload: GebruikerProjectDto, public gebruikerId: string) {}
}

export class AddSuccess implements Action {
  readonly type = GebruikerProjectenActionTypes.ADD_SUCCESS;
  constructor(public payload: GebruikerProject) {}
}

export class Remove implements Action {
  readonly type = GebruikerProjectenActionTypes.REMOVE;
  constructor(public payload: string, public gebruikerId: string) {}
}

export class RemoveSuccess implements Action {
  readonly type = GebruikerProjectenActionTypes.REMOVE_SUCCESS;
  constructor(public payload: string) {}
}

export class Failure implements Action {
  readonly type = GebruikerProjectenActionTypes.FAILURE;
  constructor(public payload: { concern: 'CREATE' | 'UPDATE' | 'LOAD' | 'DELETE'; error: any }) {}
}

export type All =
  | LoadAll
  | LoadAllSuccess
  | Add
  | AddSuccess
  | Remove
  | RemoveSuccess
  | Failure;
