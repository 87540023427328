import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, mergeMap, switchMap, concatMap, take, filter } from 'rxjs/operators';
import { of, empty, combineLatest, from } from 'rxjs';
import * as authActions from './auth.actions';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Gebruiker } from '@core-models-gebruiker';
import { Store } from '@ngrx/store';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
// import { LogoutPromptComponent } from '@app/auth/components/logout-prompt.component';
import { NotAuthenticated } from './auth.actions';

@Injectable()
export class AuthEffects {
  private gebruikersCollectionPath = 'gebruikers';

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private dialogService: MatDialog,
    private store: Store<Gebruiker>,
    private db: AngularFirestore
  ) {}


  @Effect()
  authenticateWithCredentials$ = this.actions$.pipe(
    ofType<authActions.AuthenticateWithCredentials>(authActions.AuthActionTypes.AuthenticateWithCredentials),
      map(action => action.payload),
      exhaustMap(auth =>
        this.authService
          .signInRegular(auth.email, auth.password)
          .then(credential => new authActions.AuthenticateSuccess(credential))
          .catch(error => new authActions.AuthenticateFailure(error))
      ),
    );


    @Effect()
    authenticateWithGoogle$ = this.actions$.pipe(
      ofType<authActions.AuthenticateWithGoogle>(authActions.AuthActionTypes.AuthenticateWithGoogle),
        exhaustMap(auth =>
          this.authService
            .signInWithGoogle()
            .then(credential => new authActions.AuthenticateSuccess(credential))
            .catch(error => new authActions.AuthenticateFailure(error))
        ),
      );


  // @Effect()
  // authenticateSuccess$ = this.actions$.pipe(
  //   ofType<authActions.AuthenticateSuccess>(authActions.AuthActionTypes.AuthenticateSuccess),
  //   exhaustMap(action => {

  //   }),
  //   tap(() => this.router.navigate(['/gebruikers']))
  // );



  @Effect()
   getUser$ = this.actions$.pipe(
     ofType<authActions.GetUser>(authActions.AuthActionTypes.GetUser),
     exhaustMap( auth => this.authService.gebruiker$),
     map(gebruiker => {
       if (gebruiker !==  null && gebruiker.organisatieId !== null) {
          return new authActions.UserAuthenticated(gebruiker);
       } else {
        return new authActions.NotAuthenticated();
       }
     }),
    tap(() => console.log('getUser'))
   );


   @Effect()
   logout$ = this.actions$.pipe(
     ofType<authActions.Logout>(authActions.AuthActionTypes.Logout),
     exhaustMap(() =>
         this.authService.logout()
         .then(() => {
           console.log('logoutEffect');
           return new authActions.NotAuthenticated();
         })
         .catch(error => console.log('logout error: ' + error))
       ),
     );
}
