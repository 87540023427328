import { AppSettingsService } from '../../appsettings/appsettings.service';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';

/* NgRx */
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as overzichtActions from '../overzichten/overzicht.actions';
import { AngularFirestore } from '@angular/fire/firestore';
import * as snackbarActions from '@core-store-snackbar/snackbar.actions';
import { UrenPerMedewerker } from '@core-models/overzicht';

@Injectable()
export class OverzichtEffects {
  private collectionPath = 'uren-per-medewerker';

  constructor(private db: AngularFirestore, private actions$: Actions, private appSettings: AppSettingsService) { }

  @Effect()
  loadUrenPerMedewerker$: Observable<Action> = this.actions$.pipe(
    ofType(overzichtActions.OverzichtActionTypes.LOAD_UREN_PER_MEDEWERKER),
    map((action: overzichtActions.LoadUrenPerMedewerker) => action),
    switchMap(action =>
      this.db.collection<UrenPerMedewerker>(
        this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath),
         query => query.where('maand', '==' , action.payload)
        ).snapshotChanges().pipe(
          map(actions => {
            return actions.map(documentAction => {
              const data = documentAction.payload.doc.data();
              const documentId = documentAction.payload.doc.id;
              return { documentId, ...data };
            });
          }),
          map(data => new overzichtActions.LoadUrenPerMedewerkerSuccess(data)),
          catchError(err => of(new overzichtActions.Failure({concern: 'LOAD_UREN_PER_MEDEWERKER', error: err})))
        )
    )
  );

}
