import { gebruikerprojectsAdapter } from './../gebruiker-projecten/gebruiker-projecten.reducer';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import * as fromAuthSelectors from '@core-store-auth/index';
import * as authActions from '@core-store-auth/auth.actions';
import { Store, select } from '@ngrx/store';
import { Observable, from, of } from 'rxjs';
import {
  map,
  tap,
  filter,
  take,
  switchMap,
  delay,
  catchError
} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<fromAuthSelectors.State>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    console.log('[AuthGuard] canActivate');

    const auth$ = this.authService.gebruiker$.pipe(
      switchMap(gebruiker => {
        if (gebruiker !== null && gebruiker.uid !== '') {
          console.log('[AuthGuard] user authenticated dispatch GetUser action.');
          this.store.dispatch(new authActions.GetUser());

          // afwachten totdat de store de user heeft geupdate.
          return this.store.pipe(
            select(fromAuthSelectors.selectAuthUser),
            filter(g => g.uid !== ''),
            tap(() => console.log('[AuthGuard] selectAuthUser')),
            switchMap((g) => of(true))
          );
        } else {
          console.log('[AuthGuard] redirect to login:' + JSON.stringify(state.url));
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url }
          });
          return of(false);
        }
      })
    );


    // const authObservable$ = this.store.pipe(
    //   select(fromAuthSelectors.selectIsAuthenticated),
    //   tap((isAuthenticated) => {
    //     if (isAuthenticated)  {
    //       console.log('[AuthGuard] User is authenticated!');
    //       this.store.dispatch(new authActions.GetUser());
    //     } else {
    //       this.router.navigate(['/login'], {
    //         queryParams: { returnUrl: state.url }
    //       });
    //     }
    //   }),
    //   switchMap((isAuthenticated, index) => {
    //     if (isAuthenticated) {
    //       return select(fromAuthSelectors.selectAuthUser);
    //     }
    //   }),
    //   filter(gebruiker => gebruiker !== null && gebruiker.uid !== ''),
    //   take(1),
    //   switchMap(gebruiker => {
    //     if (gebruiker.organisatieId !== null) {
    //       return of(true);
    //     } else {
    //       console.log('[AuthGuard] redirect to login');
    //       this.router.navigate(['/login'], {
    //         queryParams: { returnUrl: state.url }
    //       });
    //       return of(false);
    //     }
    //   }),
    //   catchError(err => of(false))
    //   );


    // authObservable$.subscribe(isAuthenticated => {
    //   if (!isAuthenticated) {
    //     console.log('[AuthGuard] !isAuthenticated');
    //     this.router.navigate(['/login']);
    //   } {
    //     console.log('[AuthGuard] isAuthenticated');
    //     this.router.navigate(['/dashboard']);
    //   }
    // });

    return auth$;

    // return this.store.pipe(
    //   delay(6000),
    //   select(fromAuthSelectors.selectAuthUser),
    //   map(gebruiker => {
    //     console.log('[AuthGuard] current user:' + JSON.stringify(gebruiker));
    //     if (gebruiker && gebruiker.organisatieId !== '') {
    //       console.log('[AuthGuard] user authenticated.');
    //       return true;
    //     } else {
    //       console.log('[AuthGuard] redirecting to login page...');
    //       this.router.navigate(['/login'], {
    //         queryParams: { returnUrl: state.url }
    //       });
    //       return false;
    //     }
    //   }),
    //   catchError(err => {
    //     console.log(`[AuthGuard] Error occurred: ${err}`);
    //     return of(false);
    //   })
    // );
  }
}
