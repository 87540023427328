import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../index';
import * as fromAuth from './auth.reducer';

export interface State extends fromRoot.State {
  authentication: fromAuth.AuthenticationState;
}

export const selectAuthState = createFeatureSelector<fromAuth.AuthenticationState>('authentication');

export const selectAuthUser = createSelector(
  selectAuthState,
  state => state.user
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  state => state.isAuthenticated
);

export const selectIsLoggedIn = createSelector(
  selectAuthUser,
  user => !!user
);
