import { AppSettingsService } from '../../appsettings/appsettings.service';
import { Injectable } from '@angular/core';

import { Observable, of, from } from 'rxjs';
import { mergeMap, map, catchError, switchMap, concatMap, tap } from 'rxjs/operators';

/* NgRx */
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as registratieActions from '@core-store-registratie/registratie.actions';
import { Registratie } from '@core-models-registratie';
import { AngularFirestore } from '@angular/fire/firestore';
import * as snackbarActions from '@core-store-snackbar/snackbar.actions';

@Injectable()
export class RegistratieEffects {
  private collectionPath = 'registraties';

  constructor(private db: AngularFirestore, private actions$: Actions, private appSettings: AppSettingsService) {}

  @Effect()
  loadRegistraties$: Observable<Action> = this.actions$.pipe(
    ofType(registratieActions.RegistratieActionTypes.LOAD_ALL),
    map((action: registratieActions.LoadAll) => action),

    // tslint:disable-next-line:max-line-length
    tap(action =>
      console.log(
        `[registratie.effects] query: date range begin: ${action.payload.begin.getTime()} end: ${action.payload.end.getTime()}`
      )
    ),

    switchMap(action => {
      return this.db
        .collection<Registratie>(
          this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath),
          colRef => {
            let query = colRef.where('datum', '>=', action.payload.begin.getTime());
            query = query.where('datum', '<=', action.payload.end.getTime());

            if (action.klantId && action.klantId !== null) {
              query = query.where('klantId', '==', action.klantId);
            }

            if (action.gebruikerId && action.gebruikerId !== null) {
              query = query.where('gebruikerId', '==', action.gebruikerId);
            }

            return query;
          }
        )
        .snapshotChanges()
        .pipe(
          map(actions => {
            return actions.map(documentAction => {
              const date = new Date();
              const data = documentAction.payload.doc.data();
              data.datumString = new Date(data.datum).toDateString();
              const documentId = documentAction.payload.doc.id;
              return { documentId, ...data };
            });
          }),
          // tslint:disable-next-line:max-line-length
          // tap(registraties => console.log(`[registratie.effects] registratiedatums: ${registraties.map(r => new Date(r.datum))}`)),
          map(registraties => new registratieActions.LoadAllSuccess(registraties)),
          catchError(err => {
            console.log('Firestore Error! ' + err);
            return of(new registratieActions.Failure({ concern: 'LOAD', error: err }));
          })
        );
    })
  );
  @Effect()
  loadMijnRegistraties$: Observable<Action> = this.actions$.pipe(
    ofType(registratieActions.RegistratieActionTypes.LOAD_ALL_PERSONAL),
    map((action: registratieActions.LoadAllPersonal) => action),

    // tslint:disable-next-line:max-line-length
    tap(action =>
      console.log(
        `[registratie.effects] query: date range begin: ${action.payload.begin.getTime()} end: ${action.payload.end.getTime()}`
      )
    ),

    switchMap(action => {
      return this.db
        .collection<Registratie>(
          this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath),
          colRef => {
            let query = colRef.where('datum', '>=', action.payload.begin.getTime());
            query = query.where('datum', '<=', action.payload.end.getTime());

            if (action.gebruikerId !== null) {
              query = query.where('gebruikerId', '==', action.gebruikerId);
            }
            console.log(`[registratie.effects] LoadAll.query:${query}`);
            console.log(`[registratie.effects] action:${JSON.stringify(action)}`);

            return query;
          }
        )
        .snapshotChanges()
        .pipe(
          map(actions => {
            return actions.map(documentAction => {
              const date = new Date();
              const data = documentAction.payload.doc.data();
              data.datumString = new Date(data.datum).toDateString();
              const documentId = documentAction.payload.doc.id;
              return { documentId, ...data };
            });
          }),
          // tslint:disable-next-line:max-line-length
          // tap(registraties => console.log(`[registratie.effects] registratiedatums: ${registraties.map(r => new Date(r.datum))}`)),
          map(registraties => new registratieActions.LoadAllPersonalSuccess(registraties)),
          catchError(err => of(new registratieActions.Failure({ concern: 'LOAD', error: err })))
        );
    })
  );

  @Effect()
  loadRegistratie$: Observable<Action> = this.actions$.pipe(
    ofType(registratieActions.RegistratieActionTypes.LOAD),
    map((action: registratieActions.Load) => action),
    mergeMap(action => {
      return from(
        this.db
          .collection<Registratie[]>(this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath))
          .doc<Registratie>(action.payload)
          .get()
      ).pipe(
        map(document => {
          const data = document.data() as Registratie;
          const docId = document.id;
          return { documentId: docId, ...data };
        }),
        map(registratie => new registratieActions.LoadSuccess(registratie)),
        catchError(err => of(new registratieActions.Failure({ concern: 'LOAD', error: err })))
      );
    })
  );

  @Effect()
  updateRegistratie$: Observable<Action> = this.actions$.pipe(
    ofType(registratieActions.RegistratieActionTypes.PATCH),
    map((action: registratieActions.Patch) => action),
    switchMap(action => {
      // Alleen updaten als aantal minuten meer dan 0 is anders gehele regel verwijderen
      if (action.payload.minuten > 0) {
        const documentId = action.payload.documentId;
        return from(
          this.db
            .collection<Registratie[]>(
              this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath)
            )
            .doc<Registratie>(documentId)
            .update(action.payload)
        ).pipe(
          concatMap(() => [
            new registratieActions.PatchSuccess({
              id: action.payload.documentId,
              changes: action.payload
            }),
            new snackbarActions.SnackbarOpen({
              message: 'Registratie bijgewerkt.',
              action: 'Success'
            })
          ]),
          catchError(err =>
            of(
              new registratieActions.Failure({ concern: 'UPDATE', error: err }),
              new snackbarActions.SnackbarOpen({
                message: 'Registratie is niet bijgewerkt.Foutmelding: ' + err,
                action: 'Error'
              })
            )
          )
        );
      } else {
        console.log(`[RegistratieEffect] return of(delete)`);
        return of(new registratieActions.Delete(action.payload.documentId, action.organisatieId));
      }
    })
  );

  @Effect()
  createRegistratie$: Observable<Action> = this.actions$.pipe(
    ofType(registratieActions.RegistratieActionTypes.CREATE),
    map((action: registratieActions.Create) => action),
    switchMap(action => {
      // Alleen registraties creeeren als aantal minuten meer dan 0 is.
      console.log(`Alleen registraties creeeren als aantal minuten meer dan 0 is.`);
      if (action.payload.minuten > 0) {
        const documentId = this.db.createId();
        const newValue = {...action.payload, documentId};
  
        return from(
          this.db
            .collection<Registratie[]>(
              this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath)
            )
            .doc<Registratie>(documentId)
            .set(newValue)
        ).pipe(
          concatMap(() => [
            new registratieActions.CreateSuccess(newValue),
            new snackbarActions.SnackbarOpen({
              message: 'Nieuwe registratie toegevoegd.',
              action: 'Success'
            })
          ]),
          catchError(err =>
            of(
              new registratieActions.Failure({ concern: 'CREATE', error: err }),
              new snackbarActions.SnackbarOpen({
                message: 'Registratie is niet Toegevoegd. Foutmelding: ' + err,
                action: 'Error'
              })
            )
          )
        );
      } else {
        console.log(`[RegistratieEffect] niks weggeschreven naar db`);
        return of(
          new registratieActions.Failure({
            concern: 'CREATE',
            error: 'create registratie met 0 minuten '
          }),
          new snackbarActions.SnackbarOpen({
            message: 'Registratie is niet Toegevoegd. Foutmelding: create registratie met 0 minuten Error'
          })
        );
      }
    })
  );

  @Effect()
  deleteRegistratie$: Observable<Action> = this.actions$.pipe(
    ofType(registratieActions.RegistratieActionTypes.DELETE),
    map((action: registratieActions.Delete) => action),
    mergeMap(action => {
      return from(
        this.db
          .collection<Registratie[]>(this.appSettings.getBaseCollectionPath(action.organisatieId, this.collectionPath))
          .doc<Registratie>(action.payload)
          .delete()
      ).pipe(
        concatMap(() => [
          new registratieActions.DeleteSuccess(action.payload),
          new snackbarActions.SnackbarOpen({
            message: 'Registratie is verwijderd.',
            action: 'Success'
          })
        ]),
        catchError(err =>
          of(
            new registratieActions.Failure({ concern: 'DELETE', error: err }),
            // tslint:disable-next-line:max-line-length
            new snackbarActions.SnackbarOpen({
              message: 'Er is een fout opgetreden. De registratie is niet verwijderd. Foutmelding: ' + err,
              action: 'Error'
            })
          )
        )
      );
    })
  );
}
