import { UrenPerMedewerker } from './../../models/overzicht';
import {
  All as AllOverzichtActions,
  OverzichtActionTypes
} from '../overzichten/overzicht.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const urenPerMedewerkerAdapter = createEntityAdapter<UrenPerMedewerker>({
  selectId: (urenPerMedewerker: UrenPerMedewerker) => urenPerMedewerker.documentId,
  sortComparer: false
});

export interface UrenPerMedewerkerState extends EntityState<UrenPerMedewerker> {
}

export interface State  {
  urenPerMedewerker: UrenPerMedewerkerState;
}

export const INIT_STATE: State =  {
  urenPerMedewerker: urenPerMedewerkerAdapter.getInitialState({})
};

export function reducer(state: State = INIT_STATE, { type, payload }: AllOverzichtActions
) {
  switch (type) {
    case OverzichtActionTypes.LOAD_UREN_PER_MEDEWERKER_SUCCESS:
      return {
        urenPerMedewerker: urenPerMedewerkerAdapter.setAll(payload as UrenPerMedewerker[], {...state.urenPerMedewerker})
      };
    case OverzichtActionTypes.FAILURE:
       return {
         ...state,
         error: payload
       };
    default:
      return state;
  }
}
