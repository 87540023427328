import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './page-not-found.component';

import { environment } from '../environments/environment';

/* NgRx */
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { LayoutModule } from './layout/layout.module';

/* Firebase */
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MaterialModalsModule } from './core/material/material.modals.module';
import { LoginComponent } from './login/login.component';
import { MaterialButtonsModule } from './core/material/material.buttons.module';
import { MaterialFormsModule } from './core/material/material.forms.module';
import { MaterialLayoutModule } from './core/material/material.layout.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { SnackbarEffects } from '@core-store-snackbar/snackbar.effects';
import { CoreModule } from './core/core.module';
import { clearState } from './core/store/clear.state.reducer';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    AngularFireModule.initializeApp(environment.firebaseConfig), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features

    StoreModule.forRoot({}, { metaReducers: [clearState]}),
    EffectsModule.forRoot([SnackbarEffects]),
    StoreDevtoolsModule.instrument({
      name: 'ECI UrenRegistratie App DevTools',
      maxAge: 25,
      logOnly: environment.production,
    }),
    /* Application Modules */
    LayoutModule,
    MaterialModalsModule,
    CoreModule,
    FormsModule,
    FlexLayoutModule,
    MaterialFormsModule,
    MaterialLayoutModule,
    MaterialButtonsModule
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'nl' } ],
  bootstrap: [AppComponent]
})
export class AppModule { }
