import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Action } from '@ngrx/store';

export enum SnackbarActionTypes {
    Open = '[SnackBar] Open',
    Close = '[SnackBar] Close'
}

export class SnackbarOpen implements Action {
  readonly type = SnackbarActionTypes.Open;

  constructor(public payload: {
    message: string,
    action?: string,
    config?: MatSnackBarConfig
  }) { }

}

export class SnackbarClose implements Action {
  readonly type = SnackbarActionTypes.Close;
}

export type SnackbarAction = SnackbarOpen | SnackbarClose;
