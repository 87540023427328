import { BaseComponent } from 'src/app/core/base/base.component';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '@core-store-auth/index';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'eci-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {
  title = 'UrenRegistratie';

  constructor(private store: Store<fromAuth.State>, private router: Router) {
    super();
  }

  ngOnInit() {

  }

}
