<mat-toolbar class="main-header" color="primary">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="(isHandset$ | async)">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <span>Uren Registratie</span>
    <span class="mat-caption"> v{{environment.version}}</span>
  </div>

  <!-- <button mat-button>
     <mat-icon>view_quilt</mat-icon> 
    <span>Overzicht</span>
  </button>
  <button mat-button>
    <mat-icon>timer</mat-icon>
    <span>Registratie</span>
  </button>
  <button mat-button>
    <mat-icon>settings</mat-icon>
    <span>Instellingen</span>
  </button> -->

  <!-- This fills the remaining space of the current row -->
  <span class="example-fill-remaining-space"></span>

  <button mat-button [matMenuTriggerFor]="appMenu">
    <mat-icon>account_circle</mat-icon>
    <span>{{gebruiker.displayName}}</span>
  </button>
</mat-toolbar>

<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="logout()">Uitloggen</button>
  <button mat-menu-item>Help</button>
</mat-menu>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="false" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)">
    <mat-nav-list>
      <mat-divider></mat-divider>
      <h3 matSubheader>Overzichten</h3>

      <a mat-list-item [routerLink]="['/dashboard']">
        <mat-icon matListIcon aria-label="icon">dashboard</mat-icon>
        <h4 matLine>Dashboard</h4>
      </a>

      <!-- <a mat-list-item [routerLink]="['/overzichten/uren-per-maand']">
        <mat-icon matListIcon aria-label="icon">timeline</mat-icon>
        <h4 matLine>Uren per maand</h4>
      </a>    

      <a mat-list-item [routerLink]="['/overzichten/uren-per-medewerker']">
        <mat-icon matListIcon aria-label="icon">timeline</mat-icon>
        <h4 matLine>Uren per medewerker</h4>
      </a>     -->

      <!-- <a mat-list-item [routerLink]="['/registratie-form']">
        <mat-icon matListIcon aria-label="icon">timer</mat-icon>
        <h4 matLine>Registratie Form</h4>
      </a> -->

      <mat-divider></mat-divider>
      <h3 matSubheader>Registratie</h3>

      <a mat-list-item [routerLink]="['/registratie-dag']">
        <mat-icon matListIcon aria-label="icon">today</mat-icon>
        <h4 matLine>Per Dag</h4>
      </a>

      <a mat-list-item [routerLink]="['/registratie-table']">
        <mat-icon matListIcon aria-label="icon">grid_on</mat-icon>
        <h4 matLine>Per week</h4>
      </a>

      <mat-divider></mat-divider>
      <h3 matSubheader>Instellingen</h3>

      <a mat-list-item [routerLink]="['/gebruiker-projecten']">
        <mat-icon matListIcon aria-label="icon">toggle_on</mat-icon>
        <h4 matLine>Mijn Projecten</h4>
      </a>

      <ng-container *ngIf="(isTaasc && isBeheerder) || !isTaasc">
        <mat-divider></mat-divider>
        <h3 matSubheader>Overzichten</h3>

        <a mat-list-item [routerLink]="['/overzichten/uren-per-maand']">
          <mat-icon matListIcon aria-label="icon">assignment</mat-icon>
          <h4 matLine>Uren per maand</h4>
        </a>

        <a mat-list-item [routerLink]="['/overzichten/uren-per-medewerker']">
          <mat-icon matListIcon aria-label="icon">assignment</mat-icon>
          <h4 matLine>Uren per medewerker</h4>
        </a>

        <a mat-list-item [routerLink]="['/overzichten/uren-per-project-per-medewerker']">
          <mat-icon matListIcon aria-label="icon">assignment</mat-icon>
          <h4 matLine>Uren per project / medewerker</h4>
        </a>

        <a mat-list-item [routerLink]="['/overzichten/totalen-per-project']">
          <mat-icon matListIcon aria-label="icon">assignment</mat-icon>
          <h4 matLine>Totalen per project</h4>
        </a>
      </ng-container>

      <ng-container *ngIf="isAdmin || isBeheerder">
        <mat-divider></mat-divider>
        <h3 matSubheader>Beheer</h3>

        <a mat-list-item [routerLink]="['/organisaties']" *ngIf="isAdmin">
          <mat-icon matListIcon aria-label="icon">business</mat-icon>
          <h4 matLine>Organisaties</h4>
        </a>
        <a mat-list-item [routerLink]="['/klanten']" *ngIf="isBeheerder">
          <mat-icon matListIcon aria-label="icon">contact_mail</mat-icon>
          <h4 matLine>Klanten</h4>
        </a>
        <a mat-list-item [routerLink]="['/projecten']" *ngIf="isBeheerder">
          <mat-icon matListIcon aria-label="icon">business_center</mat-icon>
          <h4 matLine>Projecten</h4>
        </a>
        <a mat-list-item [routerLink]="['/gebruikers']" *ngIf="isBeheerder">
          <mat-icon matListIcon aria-label="icon">group</mat-icon>
          <h4 matLine>Gebruikers</h4>
        </a>
      </ng-container>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="p-20">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


<mat-toolbar class="main-footer" color="primary">
  <div fxLayout fxLayoutAlign="space-around">
    <div fx>Ecare Innovatie B.V</div>
  </div>
</mat-toolbar>