import { Action } from '@ngrx/store';
import { Project } from '@core-models-project';
import { Update } from '@ngrx/entity/src/models';

export enum ProjectActionTypes {
  LOAD_ALL = '[Project] Load All',
  LOAD_ALL_SUCCESS = '[Project] Load All Success',

  LOAD = '[Project] Load',
  LOAD_SUCCESS = '[Project] Load Success',

  CREATE = '[Project] Create',
  CREATE_SUCCESS = '[Project] Create Success',

  PATCH = '[Project] Patch',
  PATCH_SUCCESS = '[Project] Patch Success',

  DELETE = '[Project] Delete',
  DELETE_SUCCESS = '[Project] Delete Success',

  FAILURE = '[Project] Failure',

  SET_CURRENT_PROJECT_ID = '[Project] Set current projectId'
}

export class SetCurrentProjectId implements Action {
  readonly type = ProjectActionTypes.SET_CURRENT_PROJECT_ID;
  constructor(public payload: string) {}
}

// export class ClearSelect implements Action {
//   readonly type = ProjectActionTypes.CancelSelect;
// }

export class Load implements Action {
  readonly type = ProjectActionTypes.LOAD;
  constructor(public payload: string, public organisatieId: string) {}
}

export class LoadSuccess implements Action {
  readonly type = ProjectActionTypes.LOAD_SUCCESS;
  constructor(public payload: Project) {}
}

export class LoadAll implements Action {
  readonly type = ProjectActionTypes.LOAD_ALL;
  constructor(public payload = null, public organisatieId: string) {}
}

export class LoadAllSuccess implements Action {
  readonly type = ProjectActionTypes.LOAD_ALL_SUCCESS;
  constructor(public payload: Project[]) {}
}

export class Create implements Action {
  readonly type = ProjectActionTypes.CREATE;

  constructor(public payload: Project, public organisatieId: string) {}
}

export class CreateSuccess implements Action {
  readonly type = ProjectActionTypes.CREATE_SUCCESS;

  constructor(public payload: Project) {}
}

export class Patch implements Action {
  readonly type = ProjectActionTypes.PATCH;

  constructor(public payload: Project, public organisatieId: string) {}
}

export class PatchSuccess implements Action {
  readonly type = ProjectActionTypes.PATCH_SUCCESS;

  constructor(public payload: Update<Project>) {}
}

export class Delete implements Action {
  readonly type = ProjectActionTypes.DELETE;

  constructor(public payload: string, public organisatieId: string) {}
}

export class DeleteSuccess implements Action {
  readonly type = ProjectActionTypes.DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export class Failure implements Action {
  readonly type = ProjectActionTypes.FAILURE;
  constructor(public payload: { concern: 'CREATE' | 'UPDATE' | 'LOAD' | 'DELETE'; error: any }) {}
}

export type All =
  | SetCurrentProjectId
  | LoadAll
  | Load
  | Create
  | Patch
  | Delete
  | LoadAllSuccess
  | LoadSuccess
  | PatchSuccess
  | CreateSuccess
  | DeleteSuccess
  | Failure;
