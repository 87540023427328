import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { delay, map, tap } from 'rxjs/operators';
import * as snackbarActions from './snackbar.actions';
import { Observable } from 'rxjs';

@Injectable()
export class SnackbarEffects {

  @Effect({
    dispatch: false
  })
  closeSnackbar: Observable<any> = this.actions.pipe(
    ofType(snackbarActions.SnackbarActionTypes.Close),
      tap(() => this.matSnackBar.dismiss())
    );

  @Effect()
  showSnackbar: Observable<any> = this.actions.pipe(
    ofType<snackbarActions.SnackbarOpen>(snackbarActions.SnackbarActionTypes.Open),
      map((action: snackbarActions.SnackbarOpen) => action.payload),
      tap(payload => this.matSnackBar.open(payload.message, payload.action, payload.config)),
      delay(2000),
      map(() => new snackbarActions.SnackbarClose())
    );

  constructor(private actions: Actions,
              private matSnackBar: MatSnackBar) {
  }

}
