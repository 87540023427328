import { Organisatie } from '@core-models-organisatie';
import {
  All as AllOrganisatieenActions,
  OrganisatieActionTypes
} from '@core-store-organisaties/organisatie.actions';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';

export const organisatiesAdapter = createEntityAdapter<Organisatie>({
  selectId: (organisatie: Organisatie) => organisatie.documentId,
  sortComparer: false
});

export interface State extends EntityState<Organisatie> {
  currentOrganisatieId?: string;
}

export const INIT_STATE: State = organisatiesAdapter.getInitialState({
  currentOrganisatieId: undefined
});

export function reducer(
  state: State = INIT_STATE,
  { type, payload }: AllOrganisatieenActions
) {
  switch (type) {
    case OrganisatieActionTypes.SET_CURRENT_ORGANISATIE_ID:
      return {
        ...state,
        currentOrganisatieId: payload
      };
    case OrganisatieActionTypes.LOAD_ALL_SUCCESS:
      return organisatiesAdapter.setAll(payload, {
        ...state,
        currentOrganisatieId: undefined
      });
    case OrganisatieActionTypes.LOAD_SUCCESS:
      return organisatiesAdapter.addOne(payload, {
        ...state,
        currentOrganisatieId: payload.documentId
      });
    case OrganisatieActionTypes.PATCH_SUCCESS:
      return organisatiesAdapter.updateOne(payload, {
        ...state,
        currentOrganisatieId: undefined
      });
    case OrganisatieActionTypes.CREATE_SUCCESS:
      return organisatiesAdapter.addOne(payload, {
        ...state,
        currentOrganisatieId: undefined
      });
    case OrganisatieActionTypes.FAILURE:
      return {
        ...state,
        error: payload
      };
    default:
      return state;
  }
}

export const getCurrentOrganisatieId = (state: State) => state.currentOrganisatieId;
