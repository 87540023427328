import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Observable, of, ObservableLike, Subject } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  public subscriptions: Subscription[] = [];
  public $unsubscribe = new Subject<void>();

  public ngOnDestroy(): void {
    console.log('[BaseComponent] ngOnDestroy');

    this.$unsubscribe.next();
    this.$unsubscribe.complete();
    
    this.subscriptions.forEach(s => {
      console.log('[BaseComponent] unsubscribe subscription');
      s.unsubscribe();
    });
    this.subscriptions = [];
  }
}
